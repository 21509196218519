.status-box {
    min-height: 100px;
    border: 1px solid #c5c5c5;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.Train {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
 min-height: 100vh;
    margin-bottom: 50px;}