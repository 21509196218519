.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* global-styles.css (or global-styles.scss) */

/* Change scrollbar thumb color */
::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;

}

/* Change scrollbar track color */
::-webkit-scrollbar-track {
    background-color: #ffffff; /* You can set this to any background color you prefer */
}

/* Change scrollbar width */
::-webkit-scrollbar {
    width: 4px;
    padding: 0 5px;
}

/* Firefox scrollbar customization */
* {
    /*padding: 0 5px;*/

    scrollbar-width: thin;
    scrollbar-color: #c5c5c5 #ffffff; /* thumb color track color */
}

/* Optional: To change scrollbar color in Firefox */
*::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;
}

*::-webkit-scrollbar-track {
    background-color: #ffffff;
}
