
.Words {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    /*height: 100vh;*/
    margin:  50px 0

}

.wordAndAction{
    display: flex;
    width: 100%;
    justify-content: space-between;
}