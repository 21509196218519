.transcribe {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*min-height: 100vh;*/
    padding: 50px 100px
}

.results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
